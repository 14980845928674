import React, { useRef, useEffect } from "react";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridActionsCellItem,
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import axios from "axios";
import {
  Avatar,
  Tooltip,
  Button,
  Checkbox,
  Grid,
  Chip,
  CircularProgress,
  Stack,
  Skeleton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import logo_Text from "../../assets/Coucal_Text.png";
import logo from "../../assets/Icon.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { useState } from "react";
import "../../css/Home.css";
import AWS from "aws-sdk";
// import animated from "../../assets/animated_logo.gif";
import { Auth } from "aws-amplify";
import InfoIcon from "@mui/icons-material/Info";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ConsoleLogger } from "aws-amplify/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import NavBar from "../Navbar/Navbar";
export default function Report() {
  const [data, setData] = useState([]);
  const [iconClicked, setIconClicked] = React.useState(false);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scheduleTechnicalCall, setScheduleTechnicalCall] =
    React.useState(false); // State for the checkbox
  const [columnDisabled, setColumnDisabled] = useState(false); // State variable to track column enable/disable
  const handleColumnToggle = () => {
    setColumnDisabled(!columnDisabled); // Toggle column state
  };
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const drawerWidth = 200;
  const [loading, setLoading] = useState(true);
  const backgroundDrawer = isDarkMode ? "black" : "#FFF";
  const colorDrawer = isDarkMode ? "#FFF" : "black";
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const location = useLocation();
  const jobId = location.state && location.state.job_id;
  const jobTitle = location.state && location.state.JobTitle;
  const technicalCall = location.state && location.state.tech_call;
  const screeningStatus = location.state && location.state.scr_status;
  const codingAssessment = location.state && location.state.coding_assessment;
  const aptitudeAssessment =
    location.state && location.state.aptitude_assessment;
  const pauseStatus = location.state && location.state.pause_status;
  const jobDisable = location.state && location.state.job_disable;
  const [checkedCodingResumeIds, setCheckedCodingResumeIds] = useState(() => {
    const storedCheckedResumeIds = localStorage.getItem("checkedCodingResumeIds");
    return new Set(storedCheckedResumeIds ? JSON.parse(storedCheckedResumeIds) : []);
  });
  console.log("screenign status", screeningStatus);
  const initialCall = location.state && location.state.init_call;
  const token = localStorage.getItem("idToken");
  const [initialRows, setInitialRows] = React.useState([]);
  const [fileName, setfileName] = useState("");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const InfoButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: -10,
    right: -10,
    padding: 0,
    borderRadius: "50%",
    // backgroundColor: "blue",
    boxShadow: theme.shadows[1],
  }));
  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [allFetchedData, setAllFetchedData] = useState([]);
  // const { technicalCallChecked } = useTechnicalCall();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const handleScheduleTechnicalCallChange = (event) => {
    setScheduleTechnicalCall(event.target.checked);
  };
  const styles = `
  .evenRow {
    background-color: #e3f2fd; /* Light blue shade for even rows */
  }

  .oddRow {
    background-color: #bbdefb; /* Lighter blue shade for odd rows */
  }
`;
  const [disabledResumeIds, setDisabledResumeIds] = useState(new Set());
  const [disabledCodeEmails, setDisabledCodeEmails] = useState(new Set());
  const [checkedAptitudeResumeIds, setCheckedAptitudeResumeIds] = useState(
    () => {
      const storedCheckedResumeIds = localStorage.getItem(
        "checkedAptitudeResumeIds"
      );
      return new Set(
        storedCheckedResumeIds ? JSON.parse(storedCheckedResumeIds) : []
      );
    }
  );
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        // Extract user attributes
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);

  const renderCheckboxCell = (params) => {
    const isInterested = params.row.interested.toLowerCase();
    const hasCallScore = params.row.call !== "Not Available";
    console.log("params", params.row);
    // Disable conditions and reasons
    let isDisabled = false;
    let disabledReason = "";
    if (pauseStatus === "0") {
      isDisabled = true;
      disabledReason = "Job is currently paused";
    }
    if (jobDisable === "True") {
      isDisabled = true;
      disabledReason = "Job is expired";
    } else if (
      filteredMappedData.has(params.row.resume_id) ||
      processedResumeIds.has(params.row.resume_id)
    ) {
      isDisabled = true;
      disabledReason = "Technical call already scheduled";
    }

    const checkbox = (
      <Checkbox
        checked={
          filteredMappedData.has(params.row.resume_id) ||
          checkedResumeIds.has(params.row.resume_id)
        }
        onChange={(event) => handleCheckboxChange(event, params)}
        color="primary"
        disabled={isDisabled}
      />
    );

    return isDisabled ? (
      <Tooltip title={disabledReason} placement="top">
        <span>{checkbox}</span>
      </Tooltip>
    ) : (
      checkbox
    );
  };
  const renderStatusCheckbox = (status, field, scores) => {
    let icon;
    let color;
    let tooltipTitle;
    const { optimizationScore, candidateScore, technicalScore, totalRanking } =
      scores;

    if (field === "aptitudeStatus") {
      switch (status) {
        case "scheduled":
          icon = <ScheduleIcon />;
          color = "orange";
          tooltipTitle = `Aptitude assessment scheduled`;
          break;
        case "completed":
        case "finished":
          icon = <CheckCircleIcon />;
          color = "green";
          tooltipTitle = `Aptitude assessment completed\nScore: ${optimizationScore}`;
          break;
        default:
          icon = <NotInterestedOutlinedIcon />;
          color = "grey";
          tooltipTitle = `Aptitude assessment not scheduled`;
      }
    } else if (field === "codingStatus") {
      switch (status) {
        case "scheduled":
          icon = <ScheduleIcon />;
          color = "orange";
          tooltipTitle = `Coding assessment scheduled`;
          break;
        case "completed":
        case "finished":
          icon = <CheckCircleIcon />;
          color = "green";
          tooltipTitle = `Coding assessment completed\nScore: ${optimizationScore}`;
          break;
        default:
          icon = <NotInterestedOutlinedIcon />;
          color = "grey";
          tooltipTitle = `Coding assessment not scheduled`;
      }
    } else if (field === "technicalStatus") {
      switch (status) {
        case "Unanswered":
        case "expired":
          icon = <CancelIcon />;
          color = "red";
          tooltipTitle = `Technical call ${status}`;
          break;
        case "triggered":
        case "pending":
        case "scheduled":
          icon = <ScheduleIcon />;
          color = "orange";
          tooltipTitle = `Technical call ${status}`;
          break;
        case "finished":
          icon = <CheckCircleIcon />;
          color = "green";
          tooltipTitle = `Technical call finished\nScore: ${technicalScore}`;
          break;
        default:
          icon = <NotInterestedOutlinedIcon />;
          color = "grey";
        // tooltipTitle = `Technical call status not found`;
      }
      tooltipTitle = `Technical call ${status}\n${
        status === "finished" ? `Score: ${technicalScore}` : ""
      }`;
    } else if (field === "initialStatus") {
      switch (status) {
        case "pending":
        case "triggered":
        case "scheduled":
          icon = <ScheduleIcon />;
          color = "orange";
          tooltipTitle = `Screening call ${status}`;
          break;
        case "finished":
          icon = <CheckCircleIcon />;
          color = "green";
          tooltipTitle = `Screening call finished\nScore: ${totalRanking}`;
          break;
        case "Unanswered":
        case "expired":
          icon = <CancelIcon />;
          color = "red";
          tooltipTitle = `Screening call ${status}`;
          break;
        default:
          icon = <NotInterestedOutlinedIcon />;
          color = "grey";
          tooltipTitle = `Screening call status not scheduled`;
      }
    } else {
      // For any other status
      switch (status) {
        case "scheduled":
          icon = <ScheduleIcon />;
          color = "green";
          tooltipTitle = `Call scheduled\nScore: N/A`;
          break;
        case "not_scheduled":
          icon = <CancelIcon />;
          color = "red";
          tooltipTitle = `Call not scheduled\nScore: N/A`;
          break;
        case "pending":
          icon = <HourglassEmptyIcon />;
          color = "orange";
          tooltipTitle = `Call pending\nScore: N/A`;
          break;
        default:
          icon = <NotInterestedOutlinedIcon />;
          color = "grey";
          tooltipTitle = `Call status not scheduled`;
      }
    }

    return (
      <Tooltip title={tooltipTitle}>
        <IconButton>
          {React.cloneElement(icon, { style: { color } })}
        </IconButton>
      </Tooltip>
    );
  };
  console.log();
  const renderCodeCheckboxCell = (params) => {
  const codingRoundStatus = params.row.coding_round_status;
  const resumeId = params.row.resume_id;
  const emailId = params.row.email_id;
  // Check if coding round is scheduled or finished
  const isCodingCompleted = codingRoundStatus === "scheduled" || codingRoundStatus === "finished";
  
  // Checkbox is checked if coding is completed or if it's in the checkedCodingResumeIds
  const isChecked = isCodingCompleted || checkedCodingResumeIds.has(resumeId);

  // Determine if checkbox should be disabled
  let isDisabled = false;
  let disabledReason = "";

  if (isCodingCompleted) {
    isDisabled = true;
    disabledReason = `Coding assessment already ${codingRoundStatus}`;
  } else if (pauseStatus === "0") {
    isDisabled = true;
    disabledReason = "Job is currently paused";
  } else if (jobDisable === "True") {
    isDisabled = true;
    disabledReason = "Job is expired";
  }

  const checkbox = (
    <Checkbox
      checked={isChecked}
      onChange={(event) => handleCodeCheckboxChange(event, params)}
      color="primary"
      disabled={isDisabled}
    />
  );

  return isDisabled ? (
    <Tooltip title={disabledReason} placement="top">
      <span>{checkbox}</span>
    </Tooltip>
  ) : (
    checkbox
  );
};
  // const aptitudeRoundStatus = params.row.aptitude_round_status;
  const renderAptitudeCheckboxCell = (params) => {
    const aptitudeRoundStatus = params.row.aptitudeStatus;
    const resumeId = params.row.resume_id;
    const isInterested = params.row.interested.toLowerCase();
    const hasCallScore = params.row.call !== "Not Available";
    // Check if aptitude round is scheduled or finished
    const isAptitudeCompleted =
      aptitudeRoundStatus === "scheduled" || aptitudeRoundStatus === "finished";

    console.log("aptitude status", params.row.aptitudeStatus);
    // Checkbox is checked if aptitude is completed or if it's in the checkedAptitudeResumeIds
    const isChecked =
      isAptitudeCompleted || checkedAptitudeResumeIds.has(resumeId);

    // Determine if the checkbox should be disabled
    let isDisabled = false;
    let disabledReason = "";

    if (isAptitudeCompleted) {
      isDisabled = true;
      disabledReason = `Aptitude assessment already ${aptitudeRoundStatus}`;
    } else if (pauseStatus === "0") {
      isDisabled = true;
      disabledReason = "Job is currently paused";
    } else if (jobDisable === "True") {
      isDisabled = true;
      disabledReason = "Job is expired";
    }

    console.log(
      `Rendering checkbox for resumeId: ${resumeId}, isChecked: ${isChecked}, isDisabled: ${isDisabled}, aptitudeRoundStatus: ${aptitudeRoundStatus}`
    );

    const checkbox = (
      <Checkbox
        checked={isChecked}
        onChange={(event) => handleAptitudeCheckboxChange(event, params)}
        color="primary"
        disabled={isDisabled}
      />
    );

    return isDisabled ? (
      <Tooltip title={disabledReason} placement="top">
        <span>{checkbox}</span>
      </Tooltip>
    ) : (
      checkbox
    );
  };
  const statusColumns = [
    ...(screeningStatus === "1"
      ? [
          {
            field: "initialStatus",
            headerName: "Screening",
            flex: 1,
            renderCell: (params) =>
              renderStatusCheckbox(params.row.initialStatus, "initialStatus", {
                optimizationScore: Number(params.row.OptimizationScore) || 0,
                candidateScore: Number(params.row.candidate_score) || 0,
                technicalScore:
                  Number(params.row.Candidate_Technical_Score) || 0,
                totalRanking: Number(params.row.call) || 0,
              }),
            align: "center",
            cellClassName: "cell-theme",
            headerAlign: "center",
          },
        ]
      : []),
    ...(technicalCall === "1"
      ? [
          {
            field: "technicalStatus",
            headerName: "Technical",
            flex: 1,
            renderCell: (params) =>
              renderStatusCheckbox(
                params.row.technicalStatus,
                "technicalStatus",
                {
                  optimizationScore: Number(params.row.OptimizationScore) || 0,
                  candidateScore: Number(params.row.candidate_score) || 0,
                  technicalScore:
                    Number(params.row.Candidate_Technical_Score) || 0,
                  totalRanking: Number(params.row.totalranking) || 0,
                }
              ),
            align: "center",
            cellClassName: "cell-theme",
            headerAlign: "center",
          },
        ]
      : []),
    ...(codingAssessment === "1"
      ? [
          {
            field: "codingStatus",
            headerName: "Coding",
            flex: 1,
            renderCell: (params) =>
              renderStatusCheckbox(
                params.row.coding_round_status,
                "codingStatus",
                {
                  optimizationScore: Number(params.row.OptimizationScore) || 0,
                  candidateScore: Number(params.row.candidate_score) || 0,
                  technicalScore:
                    Number(params.row.Candidate_Technical_Score) || 0,
                  totalRanking: Number(params.row.totalranking) || 0,
                }
              ),
            align: "center",
            cellClassName: "cell-theme",
            headerAlign: "center",
          },
        ]
      : []),
    ...(aptitudeAssessment === "1"
      ? [
          {
            field: "aptitudeStatus",
            headerName: "Aptitude",
            flex: 1,
            renderCell: (params) =>
              renderStatusCheckbox(
                params.row.aptitudeStatus,
                "aptitudeStatus",
                {
                  optimizationScore: Number(params.row.OptimizationScore) || 0,
                  candidateScore: Number(params.row.candidate_score) || 0,
                  technicalScore:
                    Number(params.row.Candidate_Technical_Score) || 0,
                  totalRanking: Number(params.row.totalranking) || 0,
                }
              ),
            align: "center",
            cellClassName: "cell-theme",
            headerAlign: "center",
          },
        ]
      : []),
  ];

  // Add these new columns to the existing columns array
  const commonColumns = [
    {
      field: "rank",
      headerName: "Rank",
      type: "text",
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      flex: 1,
      cellClassName: "cell-theme",
    },
    {
      field: "name",
      headerName: "Name",
      type: "text",
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      flex: 1,
      cellClassName: "cell-theme",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              whiteSpace: "pre-line",
              padding: 4,
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "location",
      flex: 1,
      headerName: "Current Location",
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
      renderCell: (params) => {
        const location = params.value;
        const nearby =
          params.row.nearby === true || params.row.nearby === "True";
        const name = Array.isArray(location) ? location[0] : location;
        return (
          <div
            style={{
              // width: '100%',
              textAlign: "center",
              fontWeight: nearby ? "bold" : "normal",
              whiteSpace: "pre-line",
            }}
          >
            {name}
          </div>
        );
      },
    },

    {
      field: "experience",
      flex: 1,
      headerName: "Work Experience",
      type: "number",
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
      sorting: false,
    },
    {
      field: "skills",
      headerName: "Skills",
      flex: 1, // Adjust the width as needed
      type: "text",
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
      renderCell: (params) => {
        const { value } = params;
        return (
          <Tooltip
            title={value}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "GrayText", // Change colors accordingly
                  color: "whitesmoke",
                  fontSize: "12px",
                },
              },
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "total",
      headerName: "Total Score",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
    },
    {
      field: "relocate",
      headerName: "Willing to Relocate",
      type: "text",
      flex: 1,
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
    },
    {
      field: "interested",
      headerName: "Interested",
      type: "text",
      flex: 1,
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
    },
  ];

  const technicalCallColumns = [
    {
      field: "isChecked",
      headerName: "Schedule Technical Call",
      type: "boolean",
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
      flex: 1,
      renderCell: renderCheckboxCell,
    },
  ];

  // Define the additional columns for codingAssessment
  const codingAssessmentColumns = [
    {
      field: "coding",
      headerName: "Coding Assessment",
      type: "boolean",
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
      flex: 1,
      renderCell: renderCodeCheckboxCell,
    },
  ];
  const aptitudeAssessmentColumns = [
    {
      field: "aptitude",
      headerName: "Aptitude Assessment",
      type: "boolean",
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
      flex: 1,
      renderCell: renderAptitudeCheckboxCell,
    },
  ];
  // Define the columns for when technicalCall is false
  let columns = [...commonColumns, ...statusColumns];

  if (technicalCall === "1") {
    columns = [...columns, ...technicalCallColumns];
  }

  if (codingAssessment === "1") {
    columns = [...columns, ...codingAssessmentColumns];
  }

  if (aptitudeAssessment === "1") {
    columns = [...columns, ...aptitudeAssessmentColumns];
  }

  const CustomFooter = () => (
    <>
      {technicalCall === "1" ||
      codingAssessment === "1" ||
      aptitudeAssessment === "1" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "10px",
            marginRight: "50px",
            backgroundColor: "inherit",
            marginTop: 0,
          }}
        >
          <Tooltip
            title={
              pauseStatus === "0"
                ? "Apply button is disabled as the job is paused"
                : jobDisable === "True"
                ? "Apply button is disabled as the job is expired"
                : ""
            }
            arrow
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={techScrApi}
                disabled={pauseStatus === "0" || jobDisable === "True"}
                sx={{
                  opacity:
                    pauseStatus === "0" || jobDisable === "True" ? 0.6 : 1,
                  "&.Mui-disabled": {
                    backgroundColor: "#e0e0e0",
                    color: "#9e9e9e",
                  },
                  "& .MuiButton-startIcon": {
                    color:
                      pauseStatus === "0" || jobDisable === "True"
                        ? "#9e9e9e"
                        : "inherit",
                  },
                }}
              >
                Apply
              </Button>
            </span>
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
  const CustomFooterWithPagination = (props) => (
    <GridFooterContainer>
      <GridPagination {...props} />
      <CustomFooter techScrApi={techScrApi} />
    </GridFooterContainer>
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOpen(false); // Close the drawer on smaller screens
      } else {
        setOpen(true); // Keep the drawer open on larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize on component mount
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [theme, setTheme] = React.useState(false);
  const openedMixin = (theme, isDarkMode) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
  });
  const centerGroupHeaderStyle = {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  };
  // .css-1877rg9-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer
  const closedMixin = (theme, isDarkMode) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open, isDarkMode }) => ({
    width: drawerWidth,
    backgroundColor: isDarkMode ? "black" : "#FFF",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme, isDarkMode),
      },
    }),
    ...(!open && {
      ...closedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme, isDarkMode),
      },
    }),
  }));

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleRowClick = (params) => {
    const { field, row } = params;
    if (field !== "isChecked" && field !== "coding" && field !== "aptitude") {
      const { resume_id } = row;
      // const { tech_call } = row;
      navigate("/resume-insights", {
        state: {
          resume_id,
          technicalCall,
          jobTitle,
          codingAssessment,
          aptitudeAssessment,
          pauseStatus,
          jobDisable,
          screeningStatus,
        },
      });
    }
    console.log("rows", row);
  };

  const [checkedResumeIds, setCheckedResumeIds] = useState(() => {
    let storedIds = localStorage.getItem("checkedResumeIds");
    return storedIds ? new Set(JSON.parse(storedIds)) : new Set();
  });
  const [checkedCodeEmails, setCheckedCodeEmails] = useState(() => {
    const storedCheckedEmails = localStorage.getItem("checkedCodeEmails");
    return storedCheckedEmails
      ? new Set(JSON.parse(storedCheckedEmails))
      : new Set();
  });
  const [checkedAptitudeEmails, setCheckedAptitudeEmails] = useState(() => {
    const storedCheckedEmails = localStorage.getItem("checkedAptitudeEmails");
    return storedCheckedEmails
      ? new Set(JSON.parse(storedCheckedEmails))
      : new Set();
  });
  const [scheduledCodingEmails, setScheduledCodingEmails] = useState(() => {
    const storedScheduledEmails = localStorage.getItem("scheduledCodingEmails");
    return storedScheduledEmails
      ? new Set(JSON.parse(storedScheduledEmails))
      : new Set();
  });

  const [processedResumeIds, setProcessedResumeIds] = useState(() => {
    let storedProcessedIds = localStorage.getItem("processedResumeIds");
    return storedProcessedIds
      ? new Set(JSON.parse(storedProcessedIds))
      : new Set();
  });

  const [processedCodeResumeIds, setProcessedCodeResumeIds] = useState(() => {
    let storedCodeProcessedIds = localStorage.getItem("processedCodeResumeIds");
    return storedCodeProcessedIds
      ? new Set(JSON.parse(storedCodeProcessedIds))
      : new Set();
  });
  const handleCheckboxChange = (event, params) => {
    const newValue = event.target.checked;
    const rowId = params.row.id;
    const resumeId = params.row.resume_id;
    const isInterested = params.row.interested.toLowerCase();
    const hasCallScore = params.row.call !== "Not Available";

    // Prevent changes if conditions are not met

    // Rest of your existing handleCheckboxChange logic
    const updatedRows = initialRows.map((row) =>
      row.id === rowId ? { ...row, isChecked: newValue } : row
    );
    setInitialRows(updatedRows);

    setCheckedResumeIds((prev) => {
      let updatedIds;
      if (newValue) {
        updatedIds = new Set([...prev, resumeId]);
      } else {
        updatedIds = new Set([...prev].filter((id) => id !== resumeId));
      }

      localStorage.setItem(
        "checkedResumeIds",
        JSON.stringify(Array.from(updatedIds))
      );

      return updatedIds;
    });
  };

  useEffect(() => {
    const storedIds = JSON.parse(localStorage.getItem("checkedResumeIds"));
    if (storedIds) {
      setCheckedResumeIds(new Set(storedIds));
    }
  }, []);
  useEffect(() => {
    console.log(
      "checkedAptitudeResumeIds updated:",
      Array.from(checkedAptitudeResumeIds)
    );
  }, [checkedAptitudeResumeIds]);
  useEffect(() => {
    if (pauseStatus === "0") {
      toast.info("This job is currently paused. Actions are disabled.");
    }
  }, []);
  useEffect(() => {
    console.log(
      "checkedAptitudeResumeIds updated:",
      Array.from(checkedAptitudeResumeIds)
    );
  }, [checkedAptitudeResumeIds]);

  const handleCodeCheckboxChange = (event, params) => {
    const resumeId = params.row.resume_id;

    const codingRoundStatus = params.row.coding_round_status;
    const emailId = params.row.email_id;  
    // Prevent changes if coding round is already scheduled or finished
    if (codingRoundStatus === "scheduled" || codingRoundStatus === "finished") {
      console.log(`Checkbox change prevented for resumeId: ${resumeId}, status: ${codingRoundStatus}`);
      return;
    }
  
    setCheckedCodingResumeIds((prevState) => {
      const newState = new Set(prevState);
      if (event.target.checked) {
        newState.add(resumeId);
      } else {
        newState.delete(resumeId);
      }
      // Save to localStorage immediately after state update
      localStorage.setItem("checkedCodingResumeIds", JSON.stringify(Array.from(newState)));
      return newState;
    });
  };
  // useEffect(() => {
  //   const storedCodeResumeIds = localStorage.getItem("checkedCodeResumeIds");
  //   if (storedCodeResumeIds) {
  //     setCheckedCodeResumeIds(new Set(JSON.parse(storedCodeResumeIds)));
  //   }
  // }, []);

  const handleAptitudeCheckboxChange = (event, params) => {
    const resumeId = params.row.resume_id;
    const aptitudeRoundStatus = params.row.aptitude_round_status;

    // Prevent changes if aptitude round is already scheduled or finished
    if (
      aptitudeRoundStatus === "scheduled" ||
      aptitudeRoundStatus === "finished"
    ) {
      console.log(
        `Checkbox change prevented for resumeId: ${resumeId}, status: ${aptitudeRoundStatus}`
      );
      return;
    }

    console.log("Checkbox clicked for resumeId:", resumeId);
    console.log("Checked state:", event.target.checked);

    setCheckedAptitudeResumeIds((prevState) => {
      const newState = new Set(prevState);
      if (event.target.checked) {
        newState.add(resumeId);
      } else {
        newState.delete(resumeId);
      }
      console.log("New checkedAptitudeResumeIds state:", Array.from(newState));
      localStorage.setItem(
        "checkedAptitudeResumeIds",
        JSON.stringify(Array.from(newState))
      );
      return newState;
    });
  };
  useEffect(() => {
    const storedCodeEmails = JSON.parse(
      localStorage.getItem("checkedCodeEmails")
    );
    if (storedCodeEmails) {
      setCheckedCodeEmails(new Set(storedCodeEmails));
    }

    const storedProcessedIds = JSON.parse(
      localStorage.getItem("processedCodeResumeIds")
    );
    if (storedProcessedIds) {
      setProcessedCodeResumeIds(new Set(storedProcessedIds));
    }
  }, []);
  const NoRowsOverlay = () => (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ color: "darkgray" }}
    >
      <ErrorOutlineIcon style={{ fontSize: 40, color: "#ccc" }} />
      <div>No resumes</div>
    </Stack>
  );
  const handleFileUpload = async (file) => {
    try {
      if (pauseStatus === "0" || jobDisable === "True") {
        setSnackbarMessage(
          pauseStatus === "0"
            ? "File upload is disabled as the job is paused."
            : "File upload is disabled as the job is expired."
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes
      if (file.size > maxSize) {
        setSnackbarMessage(
          "File size exceeds 50MB. Please upload a smaller file."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      let clientId = localStorage.getItem("client_id");
      const jobId = location.state && location.state.job_id;

      // Generate timestamp
      const timeStamp = new Date().toISOString().replace(/[-:.]/g, "");

      // Include original file name and prefix
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;

      const filenameWithJobId = jobId
        ? `${clientId}_${jobId}_${timeStamp}_${file.name}`
        : `${timeStamp}_${file.name}`;
      const url = `${baseUrl}/s3fileuploader?File_Name=${filenameWithJobId}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const uploadUrl = response.data.upload_url;

      if (uploadUrl) {
        // File upload with progress tracking
        const putResponse = await axios.put(uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (event) => {
            // Calculate and set upload progress percentage
            const percentage = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(percentage);
          },
        });

        if (putResponse.status === 200) {
          // Reset progress and set upload completed flag
          setUploadProgress(0);
          setUploadCompleted(true);

          // Display success message in snackbar
          setSnackbarMessage("File uploaded successfully.");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);

          // Call VerifyZipAPI
          VerifyZipAPI(filenameWithJobId);
        } else {
          // Handle unexpected response status
          const errorMsg = `Failed to upload file. Status code: ${putResponse.status}`;
          setSnackbarMessage(errorMsg);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } else {
        throw new Error("Upload URL is not available.");
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
      // Handle error scenario
      setUploadProgress(0);
      setUploadCompleted(false);
      setSnackbarMessage("Error uploading file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleJobs = () => {
    navigate("/dashboard");
  };
  // const centerGroupHeaderStyle = {
  //   "& .MuiDataGrid-columnGroupHeader": {
  //     justifyContent: "center",
  //   },
  // };
  const VerifyZipAPI = async (filenameWithJobId) => {
    try {
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
      const response = await axios.post(
        `${baseUrl}/verifyzip`,
        {
          job_id: location.state.job_id,
          file_name: filenameWithJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/zip",
          },
        }
      );

      // navigate("/dashboard")
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(
          "Server responded with a non-2xx status:",
          error.response.status
        );

        // Display an error message using Snackbar
        const errorMsg =
          error.response.data.error ||
          error.response.data.Error ||
          "An unexpected error occurred.";
        setSnackbarMessage(errorMsg);
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Show the snackbar with the error message

        console.error("Response data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error during request setup:", error.message);
      }
      console.error("Error while making API call:", error);
    }
  };

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFileUpload(selectedFile);
    }
  };

  const handleDocumentation = () => {
    navigate("/documentation");
  };

  // Function to assign unique ids to rows
  const assignUniqueIds = (rows) => {
    return rows.map((row, index) => {
      return { ...row, id: index + 1 }; // Assigning ids starting from 1
    });
  };

  const [TechScrCall, setTechScrCall] = useState([]);
  // Function to handle API call and assign unique ids to rows
  const handleAPICall = async () => {
    // setLoading(true);
    if (location.state && location.state.job_id) {
      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const response = await axios.post(
          `${baseUrl}/resume-details`,
          {
            job_id: location.state.job_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setTechScrCall(response.data.resume_files);

        // Function to calculate total score
        function calculateTotalScore(resume) {
          const optimizationScore = Number(resume.OptimizationScore) || 0;
          const candidateScore = Number(resume.candidate_score) || 0;
          const technicalScore = Number(resume.Candidate_Technical_Score) || 0;
          const totalRanking = Number(resume.totalranking) || 0;
          const moduleCount = Number(resume.total_count_modules) || 1;

          console.log("total count", moduleCount);
          const total =
            (optimizationScore +
              candidateScore +
              technicalScore +
              totalRanking) /
            moduleCount;

          // Return the total score rounded to 2 decimal places
          return total;
        }

        // Map the fetched data to the format expected by initialRows
        const mappedData = response.data.resume_files.map((resume, index) => {
          const totalScore = calculateTotalScore(resume);

          return {
            id: index + 1,
            name: resume.Name || "Not Available",
            location: resume.Current_Location[0] || "Not Available",
            experience: resume.Work_Experience,
            skills: Array.isArray(resume.Skills)
              ? resume.Skills.join(", ")
              : "Not Available",
            score:
              resume.candidate_score !== undefined
                ? resume.candidate_score
                : "Not Available",
            call:
              resume.totalranking !== undefined
                ? resume.totalranking
                : "Not Available",
            relocate: resume.Candidate_Relocation_Consent || "Not Available",
            interested: resume.Candidate_Interested_For_screening_Call || "Not Available",
            total: totalScore === 0 ? 0 : totalScore || "Not Available", // This ensures 0 is shown if totalScore is 0
            Candidate_Technical_Score:
              resume.Candidate_Technical_Score || "Not Available",
            OptimizationScore: resume.OptimizationScore || "Not Available",
            isChecked: false,
            nearby: resume.nearby,
            resume_id: resume.resume_id,
            email_id: resume.Email_ID,
            coding_assessment_check: resume.coding_assessment_check === "True",
            coding_round_status: resume.coding_round_status,
            initialStatus: resume.screening_call_status || "not scheduled",
            technicalStatus: resume.tech_call_status || "not scheduled",
            codingStatus: resume.coding_round_status || "not scheduled",
            aptitudeStatus: resume.aptitude_round_status || "not scheduled",
            totalCount: resume.total_count_modules || 0,
          };
        });

        console.log("resumes data", mappedData);

        // Sort rows with defined total scores in descending order
        const sortedData = mappedData.sort((a, b) => {
          if (typeof a.total === "number" && typeof b.total === "number") {
            return b.total - a.total;
          }
          // Place rows with undefined total scores at the end
          if (typeof a.total !== "number" && typeof b.total !== "number") {
            return 0;
          }
          // Rows with defined total scores should come before rows with undefined total scores
          return typeof a.total === "number" ? -1 : 1;
        });

        // Assigning rank as serial number
        sortedData.forEach((item, index) => {
          item.rank = index + 1;
        });

        // Update the state variables
        setAllFetchedData(sortedData);
        setInitialRows(assignUniqueIds(sortedData));
      } catch (error) {
        console.error("Error while making API call:", error);
      } finally {
        // setLoading(false);
      }
    } else {
      console.error(
        "location.state or location.state.job_id is null or undefined"
      );
    }
  };
  const filteredMappedData = new Set(
    TechScrCall.filter((resume) => resume.tech_call_status).map(
      (resume) => resume.resume_id
    )
  );
  const filteredMappedCodeData = new Set(
    TechScrCall.filter((resume) => resume.tech_call_status).map(
      (resume) => resume.resume_id
    )
  );
  console.log("coding data", filteredMappedCodeData);
  useEffect(() => {
    handleAPICall();

    // Set up an interval to refresh the table every 15 seconds
    const refreshInterval = setInterval(() => {
      handleAPICall();
    }, 15000);

    // Load scheduled coding emails from local storage
    const storedScheduledEmails = localStorage.getItem("scheduledCodingEmails");
    if (storedScheduledEmails) {
      setScheduledCodingEmails(new Set(JSON.parse(storedScheduledEmails)));
    }

    // Clear the interval on component unmount
    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken || !isAccessTokenValid(accessToken)) {
        handleInvalidAccessToken();
      } else {
        setIsLoggedIn(true);
        checkScreenActivity();
      }
    };

    const isAccessTokenValid = (accessToken) => {
      try {
        const decodedToken = new CognitoAccessToken({
          AccessToken: accessToken,
        });
        const expirationTime = decodedToken.getExpiration();
        const currentTime = Math.floor(Date.now() / 1000);
        return expirationTime > currentTime;
      } catch (error) {
        console.error(
          "Error decoding token or missing expiration time:",
          error
        );
        return false;
      }
    };

    const handleInvalidAccessToken = () => {
      localStorage.setItem("loggedIn", false);
      navigate("/");
    };

    const checkScreenActivity = () => {
      let isActive = false;

      const handleMove = () => {
        isActive = true;
      };

      window.addEventListener("mousemove", handleMove);

      setTimeout(() => {
        window.removeEventListener("mousemove", handleMove);
        if (isActive) {
          refreshAccessToken();
        }
      }, 3000);
    };

    const refreshAccessToken = () => {
      const region = process.env.REACT_APP_AWS_REGION;
      const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
      const requestBody = {
        AuthFlow: "REFRESH_TOKEN",
        ClientId: process.env.REACT_APP_CLIENT_ID_USER,
        AuthParameters: {
          REFRESH_TOKEN: localStorage.getItem("refreshToken"),
        },
      };
      const headers = {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      };

      axios
        .post(apiUrl, requestBody, { headers })
        .then((response) => {
          // Handle the response as needed
        })
        .catch((error) => {
          // Handle errors
          console.error("Error in Axios refresh POST request:", error);
        });
    };

    checkAuthentication();

    const accessTokenTimeout = setTimeout(checkAuthentication, 2 * 60 * 1000);
    const sessionTimeout = setTimeout(() => {
      // toast.error("Session expired");
      handleInvalidAccessToken();
    }, 60 * 60 * 1000);

    return () => {
      clearTimeout(accessTokenTimeout);
      clearTimeout(sessionTimeout);
    };
  }, []);

  if (!isLoggedIn) {
    return null; // or any loading indicator while checking authentication
  }

  const job_id = location.state?.job_id;

  if (!job_id) {
    return <div>Error: Job ID not found</div>;
  }
  const getClient_id = localStorage.getItem("client_id");
  console.log("Ayush-client", getClient_id);
  console.log("Ayush-jobId ", jobId);

  const techScrApi = () => {
    if (pauseStatus === "0" || jobDisable === "True") {
      toast.warning(
        pauseStatus === "0"
          ? "Actions are disabled as the job is paused."
          : "Actions are disabled as the job is expired."
      );
      return;
    }
  
    // Get resume IDs and emails
    const data1 = [...checkedResumeIds];
    
    // Get emails for coding assessment
    const codingEmails = [...checkedCodingResumeIds].map(resumeId => {
      const rowData = initialRows.find(row => row.resume_id === resumeId);
      return rowData?.email_id;
    }).filter(email => email); // Remove any undefined/null emails
    
    const data2 = JSON.stringify(codingEmails.flat());
    
    const aptitudeResumeIds = [...checkedAptitudeResumeIds];
    const data3 = JSON.stringify(aptitudeResumeIds);
  
    const tokens = localStorage.getItem("idToken");
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiCalls = [];
  
    // Add technical call API if needed
    if (data1.length > 0) {
      apiCalls.push(
        axios.post(
          `${baseUrl}/tech-scr`,
          { resume_ids: data1 },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
              "Content-Type": "application/json",
            },
          }
        )
      );
    }
  
    // Add coding assessment API if needed
    if (codingEmails.length > 0) {
      apiCalls.push(
        axios.post(
          `${baseUrl}/code/generateuser`,
          {
            email_ids_code: data2,
            client_id: getClient_id,
            job_id: jobId,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type",
              "Access-Control-Allow-Methods": "OPTIONS,POST",
            },
          }
        )
      );
    }
  
    // Add aptitude assessment API if needed
    if (aptitudeResumeIds.length > 0) {
      apiCalls.push(
        axios.post(
          `${baseUrl}/aptitude/generateuser`,
          {
            resume_ids_code: data3,
            client_id: getClient_id,
            job_id: jobId,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type",
              "Access-Control-Allow-Methods": "OPTIONS,POST",
            },
          }
        )
      );
    }
  
    Promise.all(apiCalls)
      .then((responses) => {
        // Clear states
        setCheckedResumeIds(new Set());
        setCheckedCodingResumeIds(new Set());
        setCheckedAptitudeResumeIds(new Set());
        
        // Clear localStorage
        localStorage.removeItem("checkedResumeIds");
        localStorage.removeItem("checkedCodingResumeIds");
        localStorage.removeItem("checkedAptitudeResumeIds");
  
        // Refresh data
        handleAPICall();
        
        toast.success("Selected resumes have been scheduled successfully.");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to process resume scheduling.");
        
        // Clear localStorage on error
        localStorage.removeItem("checkedResumeIds");
        localStorage.removeItem("checkedCodingResumeIds");
        localStorage.removeItem("checkedAptitudeResumeIds");
      });
  };

  const columnGroupingModel = [
    {
      groupId: "status",
      headerName: "Status",
      children: [
        { field: "codingStatus" },
        { field: "technicalStatus" },
        { field: "initialStatus" },
        { field: "aptitudeStatus" },
      ],
    },

    {
      groupId: "technicalGroup",
      headerName: "Features(ON/OFF)",
      children: [
        { field: "isChecked" },
        { field: "coding" },
        { field: "aptitude" },
      ],
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#F9F9F9",
        // width: "auto",
        // height: "auto",
        paddingLeft: 6,
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="Reports"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          pl: open ? 20 : 5,
          pr: 3,
          pb: 5,
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
        <DrawerHeader />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1} // Adjust the margin as per your requirement
        >
          <Typography
            variant="h5"
            component="h2"
            color={isDarkMode ? "#FFF" : "#142764"}
            sx={{ fontWeight: "500" }}
          >
            {jobTitle}
          </Typography>

          <Box>
            <Box
              sx={{
                bottom: -16,
                right: 10,
                position: "relative",
                display: "inline-flex",
              }}
            >
              {uploadProgress > 0 && !uploadCompleted && (
                <div style={{ marginTop: 10, marginRight: 1 }}>
                  <CircularProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                  <Box
                    sx={{
                      top: 5,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="caption">{uploadProgress}%</Typography>
                  </Box>
                </div>
              )}
            </Box>
            <Tooltip
              title={
                pauseStatus === "0"
                  ? "File upload is disabled as the job is paused"
                  : jobDisable === "True"
                  ? "File upload is disabled as the job is expired"
                  : ""
              }
              arrow
            >
              <span>
                <Button
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  onClick={handleButtonClick}
                  disabled={pauseStatus === "0" || jobDisable === "True"}
                  sx={{
                    opacity:
                      pauseStatus === "0" || jobDisable === "True" ? 0.6 : 1,
                    "&.Mui-disabled": {
                      backgroundColor: "#e0e0e0",
                      color: "#9e9e9e",
                    },
                    "& .MuiButton-startIcon": {
                      color:
                        pauseStatus === "0" || jobDisable === "True"
                          ? "#9e9e9e"
                          : "inherit",
                    },
                  }}
                >
                  Upload file
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#cbe0eb", // Change colors accordingly
                          color: "#2e3f7a",
                          fontSize: "12px",
                        },
                      },
                    }}
                    title="Please upload the resumes in valid ZIP file containing PDF, DOC and DOCX files only. The size of the file should not exceed 50 MB"
                    arrow
                    placement="top-end"
                  >
                    {/* Add the IconButton with InfoIcon as a badge */}
                    <InfoButton sx={{ backgroundColor: "white" }}>
                      <InfoIcon
                        sx={{ color: "none" }}
                        color="primary"
                        fontSize="small"
                      />
                    </InfoButton>
                  </Tooltip>
                </Button>
              </span>
            </Tooltip>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant="filled"
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
        {/* <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          marginBottom={1}
        >
          <Button
            variant="contained"
            sx={{ marginBottom: 2, marginTop: 2 }}
            onClick={techScrApi}
          >
            Apply
          </Button>
        </Grid> */}

        <Box
          sx={{
            // "& .header-theme": {
            //   backgroundColor: isDarkMode ? "#171717" : "#CFD4FF",
            //   width: "auto",
            // },
            // height: "100vh",
            pb: 4,
          }}
        >
          <DataGrid
            rows={initialRows}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "evenRow" : "oddRow"
            }
            columnGroupingModel={columnGroupingModel}
            experimentalFeatures={{ columnGrouping: true }}
            onCellClick={handleRowClick}
            sx={{
              ...centerGroupHeaderStyle,
              // "& .MuiDataGrid-columnHeaders": {
              //   backgroundColor: "#f0f0f0",
              // },
              // "& .MuiDataGrid-cell": {
              //   borderRight: "1px solid #e0e0e0",
              // },
              display: "flex",
              justifyContent: "center",
              backgroundColor: isDarkMode ? "#000" : "#FFF",
              color: isDarkMode ? "#FFF" : "#000",
              border: "1px solid #ccc",
              height: "70vh",
              "& .MuiDataGrid-viewport": {
                overflowY: "auto", // Allow vertical scrolling as needed
                overflowX: "scroll",
                overflowBlock: "scroll",
                "&::-webkit-scrollbar": {
                  position: "fixed",
                  width: "8px",
                  height: "8px",
                },
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
            classes={{
              row: "gridRow",
              boldLocation: {
                fontWeight: "bold",
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            // slots={{
            //   toolbar: GridToolbar,
            //   loadingOverlay: CustomLoadingOverlay,
            // }}
            components={{
              Toolbar: GridToolbar,
              Footer: CustomFooterWithPagination,
              // loadingOverlay: CustomLoadingOverlay,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            // loading={loading}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
