import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
// import HelpOutlineIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Button,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Avatar,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
  // makeStyles,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  DatePicker,
  DigitalClock,
  LocalizationProvider,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { styled } from "@mui/material/styles";
// import { Input } from "postcss";

const steps = [
  "Job Details",
  "Other Details",
  "Job Description",
  "Features",
  "Initial Screening Questions",
  // "Technical Screening Questions",
  "Complete!",
];

export default function Step4({
  handleBack,
  handleNext,
  formData,
  setFormData,
  setTechnicalCheckedParent,
}) {
  const [activeStep, setActiveStep] = useState(3);
  const [daySelectionError, setDaySelectionError] = useState("");
  const [codingDateError, setCodingDateError] = useState("");
  const [codingLanguageError, setCodingLanguageError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  // const [initialChecked, setInitialChecked] = useState(true);
  // const [technicalChecked, setTechnicalChecked] = useState(false);
  const [selectedDays, setSelectedDays] = useState(formData.week_range || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [startTime, setStartTime] = useState(formData.perday_start_time || "");
  const [endTime, setEndTime] = useState(formData.perday_end_time || "");
  const [showAdditionalContent, setShowAdditionalContent] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    formData.call_schedule || ""
  );
  const [targetDate, setTargetDate] = useState(
    formData.target_hiring_date || ""
  );
  const [error, setError] = React.useState(false);

  const [checked, setChecked] = React.useState(formData.skipHoliday || 0);
  const [initialChecked, setInitialChecked] = useState(true);
  const [technicalChecked, setTechnicalChecked] = useState(false);
  const [codingChecked, setCodingChecked] = useState(false);
  const [aptitudeChecked, setAptitudeChecked] = useState(false);
  const [codingDate, setCodingDate] = useState(
    formData.coding_assessment?.date
  );
  const [codingEndDate, setCodingEndDate] = useState(
    formData.coding_assessment?.end_date
  );

  const [selectedLanguages, setSelectedLanguages] = useState(
    formData.coding_assessment?.language || ""
  );

  console.log("technicalChecked", technicalChecked);
  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  // console.log("initrial ....",initialChecked);
  // console.log("technicalChecked ....",technicalChecked);
  console.log("selectedDays ....", selectedDays);
  console.log("startTime ....", startTime);
  console.log("endTime ....", endTime);
  console.log("showAdditionalContent ....", showAdditionalContent);
  console.log("call schedule...", selectedDate);
  console.log("hiring date", targetDate);
  console.log("...checked fro skip holiday ...", checked);
  console.log("coding Date", codingDate);
  console.log("language", selectedLanguages);
  console.log("coding_check", codingChecked);
  console.log("aptitude_check", aptitudeChecked);
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleCancel = () => {
    setOpenDialog(true);
    // Navigate to the dashboard page
  };
  const handleCodingChecked = (event) => {
    setCodingChecked(event.target.checked);
  };
  const handleAptitudeChecked = (event) => {
    setAptitudeChecked(event.target.checked);
  };
  const handleTimeChange = (type, time) => {
    console.log("Type:", type);
    console.log("Time:", time);
    const selectedTime = dayjs(time);

    // Convert moment object to string
    // const timeString = time.format("HH:mm:ss");

    if (type === "start") {
      setStartTime(time);
      if (endTime && selectedTime.isAfter(dayjs(endTime))) {
        setEndTime(null); // Reset end time if it's before the start time
      }
    } else if (type === "end") {
      setEndTime(time);
      if (startTime && selectedTime.isBefore(dayjs(startTime))) {
        // setEndTime(null); // Reset end time
        setShowMessage(true); // Display message
        return; // Exit function if end time is before start time
      }
      setShowMessage(false);
    }
  };
  const handleInitialChecked = (event) => {
    setInitialChecked(event.target.checked);
    updateAdditionalContent(event.target.checked, technicalChecked);
  };

  const handleTechnicalChecked = (event) => {
    setTechnicalChecked(event.target.checked);
    setTechnicalCheckedParent(event.target.checked);
    updateAdditionalContent(initialChecked, event.target.checked);
  };
  console.log("technicalChecked", technicalChecked);

  const updateAdditionalContent = (initial, technical) => {
    setShowAdditionalContent(initial || technical);
  };
  // console.log("technical Call Checked", technicalCallChecked)
  // Function to handle date selection
  const handleDateChange = (date) => {
    const parsedDate = dayjs(date);
    if (parsedDate.isBefore(dayjs().startOf("day"))) {
      setError(true);
      // setSelectedDate(null);
      setStartDateError("Start date cannot be in the past.");
    } else {
      setError(false);
      setSelectedDate(parsedDate);
      setStartDateError("");
      if (targetDate && parsedDate.isAfter(targetDate)) {
        setEndDateError("End date must be after start date.");
      } else {
        setEndDateError("");
      }
    }
  };

  const handleEstimateChange = (date) => {
    const estimateDate = dayjs(date);
    if (estimateDate.isBefore(dayjs().startOf("day"))) {
      setError(true);
      // setTargetDate(null);
      setEndDateError("End date cannot be in the past.");
    } else {
      setError(false);
      setTargetDate(estimateDate);
      setEndDateError("");
      if (selectedDate && estimateDate.isBefore(selectedDate)) {
        setEndDateError("End date must be after start date.");
      } else {
        setEndDateError("");
      }
    }
  };
  const handleCodingDateChange = (date) => {
    setCodingDate(date);
    setCodingDateError("");
  };
  const handleCodingEndDateChange = (date) => {
    setCodingEndDate(date);
    setCodingDateError("");
    setCodingLanguageError("");
  };
  const handleLanguageChange = (event) => {
    setSelectedLanguages(event.target.value);
  };
  console.log("current language", selectedLanguages);

  const disablePastDates = (date) => {
    // Disable any date before today
    return date.isBefore(dayjs(), "day");
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmCancel = () => {
    // Add your cancel logic here
    window.location.href = "/dashboard";
  };
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M20 6.41L18.59 5 12 11.59 5.41 5 4 6.41 10.59 13 4 19.59 5.41 21 12 14.41 18.59 21 20 19.59 13.41 13 20 6.41z"/></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const dayAbbreviationToFullName = (dayAbbreviation) => {
    switch (dayAbbreviation) {
      case "Mo":
        return "Monday";
      case "Tu":
        return "Tuesday";
      case "We":
        return "Wednesday";
      case "Th":
        return "Thursday";
      case "Fr":
        return "Friday";
      case "Sa":
        return "Saturday";
      case "Su":
        return "Sunday";
      default:
        return "";
    }
  };

  const handleDayChecked = (day) => {
    const fullName = dayAbbreviationToFullName(day);

    // Check if fullName is already in selectedDays
    const isAlreadySelected = selectedDays.includes(fullName);

    let updatedSelectedDays;
    if (isAlreadySelected) {
      // If already selected, remove it from selectedDays array
      updatedSelectedDays = selectedDays.filter(
        (selectedDay) => selectedDay !== fullName
      );
    } else {
      // If not already selected, add it to selectedDays array
      updatedSelectedDays = [...selectedDays, fullName];
    }

    // Update selectedDays state
    setSelectedDays(updatedSelectedDays);

    // Clear the error message if at least one day is selected
    if (updatedSelectedDays.length > 0) {
      setDaySelectionError("");
    }
  };

  // console.log("..steps.lengh..", handleNext);

  const handleNextStep = () => {
    // Update form data
    if ((initialChecked || technicalChecked) && selectedDays.length === 0) {
      if (!selectedDate) {
        setStartDateError("Please select a start date.");
        return;
      }

      if (!targetDate) {
        setEndDateError("Please select an end date.");
        return;
      }

      if (dayjs(targetDate).isBefore(dayjs(selectedDate))) {
        setEndDateError("End date must be after start date.");
        return;
      }
    }
    if ((initialChecked || technicalChecked) && selectedDays.length === 0) {
      setDaySelectionError(
        "Please select at least one day for scheduling calls."
      );
      return; // Don't proceed to next step
    }
    if ((initialChecked || technicalChecked) && (!startTime || !endTime)) {
      setStartTimeError(!startTime);
      setEndTimeError(!endTime);
      return; // Don't proceed to next step
    }
    setStartTimeError(false);
    setEndTimeError(false);
    // Clear the error if validation passes
    setDaySelectionError("");
    if ((initialChecked || technicalChecked) && selectedDays.length === 0) {
      setDaySelectionError(
        "Please select at least one day for scheduling calls."
      );
      return; // Don't proceed to next step
    }
    setDaySelectionError("");
    setCodingDateError("");
    setCodingLanguageError("");
    setStartDateError("");
    setEndDateError("");
    setStartTimeError(false);
    setEndTimeError(false);
    setDaySelectionError("");
    setCodingDateError("");
    setCodingLanguageError("");
    if (codingChecked) {
      if (!codingDate || !codingEndDate) {
        setCodingDateError(
          "Please select both start and end date for the coding assessment."
        );
        return; // Don't proceed to next step
      }
      if (dayjs(codingEndDate).isBefore(dayjs(codingDate))) {
        setCodingDateError("End date cannot be before the start date.");
        return; // Don't proceed to next step
      }
      if (!selectedLanguages) {
        setCodingLanguageError(
          "Please select a programming language for the coding assessment."
        );
        return; // Don't proceed to next step
      }
    }
    setFormData({
      ...formData,
      call_schedule: selectedDate, // Update call_schedule with selectedDate
      target_hiring_date: targetDate,
      week_range: selectedDays,
      perday_start_time: startTime,
      perday_end_time: endTime,
      skipHoliday: checked,
      screening_call: initialChecked,
      technical_call: technicalChecked,
      aptitude_checked: aptitudeChecked,
      coding_assessment: codingChecked
        ? {
            coding_start_date: codingDate,
            coding_end_date: codingEndDate,
            coding_language: selectedLanguages,
            coding_check: codingChecked,
          }
        : null,
    });
    if (!initialChecked) {
      handleNext(); // Move to the next step
      handleNext(); // Skip one more step
    } else {
      handleNext(); // Proceed to the next step normally
    }
  };
  console.log("form step4", formData);
  const LabelWithHelp = ({ label, helpText }) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={1}
    >
      <Typography variant="subtitle1" sx={{ color: "#142764" }}>
        {label}
      </Typography>
      <Tooltip
        title={<Typography variant="body2">{helpText}</Typography>}
        arrow
        placement="top"
        sx={{ cursor: "pointer" }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#F8F8FF",
              color: "black",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "8px 12px",
            },
          },
          arrow: {
            sx: {
              color: "darkblue", // Match arrow color with tooltip background
              boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
            },
          },
        }}
      >
        <HelpOutlineIcon
          fontSize="small"
          sx={{
            color: "#142764",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "scale(1.1)",
              color: "#1a75ff",
            },
          }}
        />
      </Tooltip>
    </Box>
  );
  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Typography
          sx={{
            fontSize: "25px",
            color: "#142764",
            textAlign: "center",
            mb: 3,
          }}
        >
          Features
        </Typography>
        <Card
          sx={{
            width: "100%",
            maxWidth: "800px",
            mt: 2,
            boxShadow: 3,
            borderRadius: 2,
            transition: "0.3s",
            "&:hover": {
              boxShadow: 6,
            },
          }}
        >
          <CardHeader
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="#142764">
                  AI Call Types
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 4,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Initial Screening Call
                </Typography>
                {/* <Tooltip
                  sx={{ cursor: "pointer" }}
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#AFDBF5",
                        color: "#1a1f71",
                      },
                    },
                  }}
                  title="Include an initial screening call"
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{ color: "#142764", cursor: "pointer" }}
                  />
                </Tooltip> */}
                <Checkbox
                  checked={initialChecked}
                  onChange={handleInitialChecked}
                  // sx={{
                  //   color: '#142764',
                  //   '&.Mui-checked': {
                  //     color: '#142764',
                  //   },
                  //   // ml: 'auto'
                  // }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: 9 }}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Technical Screening Call
                </Typography>
                <Checkbox
                  checked={technicalChecked}
                  onChange={handleTechnicalChecked}
                  // sx={{
                  //   color: '#142764',
                  //   '&.Mui-checked': {
                  //     color: '#142764',
                  //   },
                  //   // ml: 'auto'
                  // }}
                />
              </Box>
              <Tooltip
                title={
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Initial Screening Call:
                    </Typography>
                    <Typography variant="body2">
                      By enabling this, Coucal will start calling the people
                      using an AI call to gathers information like interest in
                      the job, specific location, notice period, and salary. If
                      you enable this, it will call all the candidates resume
                      you will upload.
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Technical Screening Call:
                    </Typography>
                    <Typography variant="body2">
                      By enabling this, Coucal will conduct a technical round
                      based on skill requirements and candidate experience.
                      You'll be able to choose which candidates to invite for a
                      technical interview on the job results page once Initial
                      Screening call is finished.
                    </Typography>
                  </Box>
                }
                arrow
                placement="top"
                sx={{ cursor: "pointer" }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F8F8FF",
                      color: "black",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "12px 16px",
                      maxWidth: "300px", // Limit the width for better readability
                    },
                  },
                  arrow: {
                    sx: {
                      color: "darkblue", // Match arrow color with tooltip background
                      boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                    },
                  },
                }}
              >
                <HelpOutlineIcon
                  fontSize="small"
                  sx={{
                    color: "#142764",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    marginBottom: "18px",
                    "&:hover": {
                      transform: "scale(1.1)",
                      color: "#1a75ff",
                    },
                  }}
                />
              </Tooltip>
            </Box>
          </CardContent>
          {showAdditionalContent && (
            <>
              <CardHeader
                title="Call Schedule Details"
                titleTypographyProps={{ variant: "h6", color: "#142764" }}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography mb={1.5}>Call Start Date </Typography>
                    {/* <LabelWithHelp
                      label="Call Start Date"
                      helpText="Select the date from which AI calls should start"
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Choose the date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        inputFormat="yyyy/MM/dd"
                        shouldDisableDate={disablePastDates}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            // helperText={startDateError}
                            // error={!!startDateError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    
                    {startDateError && (
                      <Typography
                        color="error"
                        sx={{ mt: 1, fontSize: "0.75rem" }}
                      >
                        {startDateError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <LabelWithHelp
                      label="Call End Date"
                      helpText="Choose the Date range when AI Call should happen including Initial and Technical Screening."
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Choose the date"
                        value={targetDate}
                        onChange={handleEstimateChange}
                        shouldDisableDate={disablePastDates}
                        renderInput={(params) => (
                          <TextField {...params} error={!!endDateError} />
                        )}
                      />
                      
                    </LocalizationProvider>
                    {endDateError && (
                      <Typography
                        color="error"
                        sx={{ mt: 1, fontSize: "0.75rem" }}
                      >
                        {endDateError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" gap={5}>
                      <Typography variant="body1">Skip holidays</Typography>
                      {/* </Box> */}
                      {/* <Box display="flex"  alignItems="center"> */}
                      <FormControlLabel
                        control={
                          <Android12Switch
                            defaultChecked
                            value="android12"
                            checked={checked}
                            onChange={handleCheck}
                          />
                        }
                      />
                    </Box>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Check this if you want the system not to call on a
                          holiday
                        </Typography>
                      }
                      arrow
                      placement="top"
                      sx={{ cursor: "pointer", ml: 1 }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#F8F8FF",
                            color: "black",
                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            padding: "8px 12px",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "darkblue", // Match arrow color with tooltip background
                            boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                          },
                        },
                      }}
                    >
                      <HelpOutlineIcon
                        fontSize="small"
                        sx={{
                          color: "#142764",
                          cursor: "pointer",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            transform: "scale(1.1)",
                            color: "#1a75ff",
                          },
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    gap={5}
                    mt={0}
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={6}>
                        Select Days
                      </Typography>

                      {/* </Box> */}
                      <FormGroup row>
                        {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map(
                          (day) => (
                            <Avatar
                              key={day}
                              sx={{
                                bgcolor: selectedDays.includes(
                                  dayAbbreviationToFullName(day)
                                )
                                  ? "#142764"
                                  : "lightblue",
                                color: selectedDays.includes(
                                  dayAbbreviationToFullName(day)
                                )
                                  ? "#FFF"
                                  : "#142764",
                                fontSize: 15,
                                mr: 1,
                                cursor: "pointer",
                                transition: "0.3s",
                                "&:hover": {
                                  bgcolor: "#142764",
                                  color: "#FFF",
                                  transform: "scale(1.1)",
                                },
                              }}
                              onClick={() => handleDayChecked(day)}
                            >
                              {day}
                            </Avatar>
                          )
                        )}
                      </FormGroup>
                    </Box>
                    <Tooltip
                      sx={{ cursor: "pointer" }}
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#F8F8FF",
                            color: "black",
                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            padding: "8px 12px",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "darkblue", // Match arrow color with tooltip background
                            boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                          },
                        },
                      }}
                      title={
                        <Typography variant="body2">
                          Select Days of the week when system can call
                        </Typography>
                      }
                    >
                      <HelpOutlineIcon
                        fontSize="small"
                        sx={{
                          color: "#142764",
                          cursor: "pointer",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            transform: "scale(1.1)",
                            color: "#1a75ff",
                          },
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    {daySelectionError && (
                      <Typography color="error" sx={{ mt: 0 }}>
                        {daySelectionError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography mb={1.5}>Time Start </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        value={startTime}
                        onChange={(time) => handleTimeChange("start", time)}
                        renderInput={(params) => (
                          <TextField {...params} error={startTimeError} />
                        )}
                      />
                    </LocalizationProvider>
                    
                    {startTimeError && (
                      <Typography
                        color="error"
                        sx={{ mt: 1, fontSize: "0.75rem" }}
                      >
                        Please select a start time
                      </Typography>
                    )}
                  </Grid>
                  <Grid item mt={0} xs={12} md={6}>
                    <LabelWithHelp
                      label="Time End"
                      helpText="Choose Time Range of any given day when the system can call. We require at least 1 hour difference between Start and End Time"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        value={endTime}
                        onChange={(time) => handleTimeChange("end", time)}
                        renderInput={(params) => (
                          <TextField {...params} error={endTimeError} />
                        )}
                      />
                    </LocalizationProvider>
                    {endTimeError && (
                      <Typography
                        color="error"
                        sx={{ mt: 1, fontSize: "0.75rem" }}
                      >
                        Please select an end time
                      </Typography>
                    )}
                    {showMessage && (
                      <Typography
                        sx={{ mt: 1, fontSize: "0.75rem", color: "red" }}
                      >
                        End time should be greater than start time
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </>
          )}
        </Card>
        <Card
          sx={{
            width: "100%",
            maxWidth: "800px",
            mt: 2,
            boxShadow: 3,
            borderRadius: 2,
            transition: "0.3s",
            "&:hover": {
              boxShadow: 6,
            },
          }}
        >
          <CardHeader
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="#142764">
                  Coding Assessment
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Coding Assessment
                </Typography>

                <Checkbox
                  checked={codingChecked}
                  onChange={handleCodingChecked}
                />
              </Box>
              <Tooltip
                title={
                  <Typography variant="body2">
                    Check this if you want to include Coding Assessment to
                    evaluate practical programming skills in your hiring
                    process. You will be able to choose the candidate in the job
                    result page to whom you want to send the coding assessment
                  </Typography>
                }
                arrow
                placement="left"
                sx={{ cursor: "pointer" }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F8F8FF",
                      color: "black",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "8px 12px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "darkblue", // Match arrow color with tooltip background
                      boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                    },
                  },
                }}
              >
                <HelpOutlineIcon
                  fontSize="small"
                  padding={0}
                  sx={{
                    color: "#142764",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    marginBottom: "18px",
                    padding: 0,
                    "&:hover": {
                      transform: "scale(1.1)",
                      color: "#1a75ff",
                    },
                  }}
                />
              </Tooltip>
            </Box>
            {codingChecked && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} mt={3} mb={2}>
                  <Typography mb={1.5}>Start Date </Typography>
                  {/* <LabelWithHelp
                    label="Start Date"
                    helpText="Select the date from which Coding assessment should start"
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start date"
                      value={codingDate}
                      shouldDisableDate={disablePastDates}
                      onChange={handleCodingDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} mt={2.8}>
                  <LabelWithHelp
                    label="End Date"
                    helpText="Choose the Date range for Coding Assessment"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={codingEndDate}
                      shouldDisableDate={disablePastDates}
                      onChange={handleCodingEndDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                {codingDateError && (
                  <div style={{ marginLeft: 25 }}>
                    <Typography color="error">{codingDateError}</Typography>
                  </div>
                )}

                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" color="#142764">
                      Choose Programming language
                    </Typography>
                  </Box>
                  <FormControl sx={{ minWidth: 258, marginTop: 1 }}>
                    <InputLabel id="demo-simple-select-label">
                      Languages
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedLanguages}
                      onChange={handleLanguageChange}
                      label="Languages"
                    >
                      <MenuItem value="javascript-18.15.0">JavaScript</MenuItem>
                      <MenuItem value="python-3.10.0">Python</MenuItem>
                      <MenuItem value="cpp-10.2.0">C++</MenuItem>
                      <MenuItem value="java-15.0.2">Java</MenuItem>
                      <MenuItem value="csharp-6.12.0">C#</MenuItem>
                      <MenuItem value="php-8.2.3">PHP</MenuItem>
                      <MenuItem value="go-1.16.2">Go</MenuItem>
                      <MenuItem value="powershell-7.1.4">Powershell</MenuItem>
                      <MenuItem value="ruby-3.0.1">Ruby</MenuItem>
                      <MenuItem value="scala-3.2.2">Scala</MenuItem>
                      <MenuItem value="perl-5.36.0">Perl</MenuItem>
                      <MenuItem value="swift-5.3.3">Swift</MenuItem>
                      <MenuItem value="kotlin-1.8.20">Kotlin</MenuItem>
                    </Select>
                  </FormControl>
                  {codingLanguageError && (
                    <Typography color="error" sx={{ mt: 1 }}>
                      {codingLanguageError}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Select your preferred programming language for the
                          coding challenge
                        </Typography>
                      }
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#F8F8FF",
                            color: "black",
                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            padding: "8px 12px",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "darkblue",
                            boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)",
                          },
                        },
                      }}
                    >
                      <IconButton size="small" padding={0}>
                        <HelpOutlineIcon
                          fontSize="small"
                          sx={{
                            color: "#142764",
                            cursor: "pointer",
                            transition: "all 0.3s ease",
                            padding: "0px",
                            "&:hover": {
                              transform: "scale(1.1)",
                              color: "#1a75ff",
                            },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "100%",
            maxWidth: "800px",
            mt: 2,
            boxShadow: 3,
            borderRadius: 2,
            transition: "0.3s",
            "&:hover": {
              boxShadow: 6,
            },
          }}
        >
          <CardHeader
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="#142764">
                  Aptitude Assessment
                </Typography>
              </Box>
            }
          />
          <CardContent
          // sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Aptitude Assessment
                </Typography>
                <Checkbox
                  checked={aptitudeChecked}
                  onChange={handleAptitudeChecked}
                />
              </Box>
              <Tooltip
                title={
                  <Typography variant="body2">
                    Check this if you want to include Aptitude Test to evaluate
                    General Problem solving skills in your hiring process. You
                    will be able to choose the candidate in the job result page
                    to whom you want to send the coding assessment
                  </Typography>
                }
                arrow
                placement="left"
                sx={{ cursor: "pointer" }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F8F8FF",
                      color: "black",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "8px 12px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "darkblue", // Match arrow color with tooltip background
                      boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                    },
                  },
                }}
              >
                <HelpOutlineIcon
                  fontSize="small"
                  sx={{
                    color: "#142764",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    marginBottom: "18px",
                    padding: 0,
                    "&:hover": {
                      transform: "scale(1.1)",
                      color: "#1a75ff",
                    },
                  }}
                />
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "800px",
            mt: 3,
          }}
        >
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancel
          </Button>
          <Box>
            <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Button variant="contained" onClick={handleNextStep}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
            pl: 3,
            mb: -2,
          }}
        >
          <WarningIcon color="warning" sx={{ marginRight: -2 }} />
          <DialogTitle>Are you sure?</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the job creation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmCancel}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </Box>
  );
}
